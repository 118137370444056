<template>
    <div v-if="tabs && tabs.length" :class="currentTabsHeight()">
        <span
            v-if="!webshopSettings().isOneDispatchType"
            class="flex w-fit gap-2 rounded-lg sm:gap-0">
            <button
                v-for="tab in tabs"
                :key="tab.value"
                class="w-full cursor-pointer items-center overflow-hidden rounded-b-none rounded-t-lg px-8 py-4 text-lg font-extrabold transition delay-100 ease-in-out"
                :class="[
                    !isMounted && tab.value === DispatchTypeOrderBuddy.DELIVER || isMounted && checkout().input.dispatchType === tab.value ? 'rounded-t-lg bg-white text-black hover:bg-white' : 'bg-gray-200 text-gray-600 hover:bg-gray-300',
                    !webshopSettings().global.dispatchTypes.includes(tab.value) ? '!cursor-not-allowed' : ''
                ]"

                :disabled="!webshopSettings().global.dispatchTypes.includes(tab.value)"
                @click.prevent="dispatchTypeChanged(tab.value)"
            >
                {{ $t(tab.value) }}
            </button>
        </span>
        <div
            class="flex min-h-0 flex-col justify-around bg-white p-8 shadow-xl transition-all" :class="{
                'rounded-tr-lg': webshopSettings()?.global?.prizewheelActive,
                'rounded-r-lg': !webshopSettings()?.global?.prizewheelActive
            }">
            <transition name="fade-scale" mode="out-in">
                <KeepAlive>
                    <component :is="selectedTabComponent" />
                </KeepAlive>
            </transition>
        </div>
        <div v-if="webshopSettings()?.global?.prizewheelActive" class="relative max-w-5xl overflow-hidden rounded-b-lg bg-gray-800">
            <PrizeWheel />
        </div>
    </div>
</template>

<script setup lang="ts">
    import HeroDelivery from '~/components/Hero/HeroDelivery.vue'
    import HeroPickup from '~/components/Hero/HeroPickup.vue'
    import { webshopSettings } from '~/store/webshopSettings'
    import PrizeWheel from '~/components/PrizeWheel.vue'
    import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
    import { checkout } from '~/store/checkout'
    const { t } = useI18n()
    const tabs = [
        {
            label: t(DispatchTypeOrderBuddy.DELIVER) + (!webshopSettings().deliveryIsEnabled ? ` (${t('shop.cart.notAvailable')})` : ''),
            value: DispatchTypeOrderBuddy.DELIVER,
            attrs: {
                disabled: !webshopSettings().deliveryIsEnabled
            }
        },
        {
            label: t(DispatchTypeOrderBuddy.PICK_UP),
            value: DispatchTypeOrderBuddy.PICK_UP,
            attrs: {
                disabled: !webshopSettings().pickupIsEnabled
            }
        }
    ]
    // TODO: The hasMounted mechanism is needed because somehow, the postal code from the localStorage from the Pinia stores is set before the client side hydration is done, resulting into hydration mismatches
    // TODO: It is not clear what is the issue - it is either the @pinia-plugin-persistedstate/nuxt or @formkit/nuxt.
    // TODO: We should recheck when https://foodticket.atlassian.net/browse/WSV3-40 is being done if perhaps a new formkit version fixes this issue
    // TODO: We could also try to make a custom localStorage implementation in Pinia using e.g. https://pinia.vuejs.org/cookbook/composables.html#SSR to see if this issue. It should also be checked if manually restoring localStorage onMounted resolves the issue.
    // TODO: Check if the https://nuxt.com/docs/guide/directory-structure/components#paired-with-a-client-component approach works (it may be cleaner and an easier pattern to repeat in other places)
    const selectedTabComponent = shallowRef(HeroDelivery)
    const isMounted = ref(false)
    onMounted(async () => {
        selectedTabComponent.value = checkout().input.dispatchType === DispatchTypeOrderBuddy.DELIVER ? HeroDelivery : HeroPickup
        isMounted.value = true
    })
    function currentTabsHeight () {
        const shouldShowEmergencyBreakNotification = webshopSettings()?.global?.emergencyBrake?.closedTemporarily || webshopSettings()?.global?.emergencyBrake?.deliveryClosedTemporarily || webshopSettings()?.global?.emergencyBrake?.pickupClosedTemporarily || webshopSettings()?.global?.emergencyBrake?.deliveryMinTimeTemporarily || webshopSettings()?.global?.emergencyBrake?.pickupMinTimeTemporarily
        if (webshopSettings()?.global?.prizewheelActive) {
            if (shouldShowEmergencyBreakNotification) {
                return 'md:min-h-[39rem] lg:min-h-[37rem]'
            } else {
                return ' md:min-h-[35.3rem] lg:min-h-[33.5rem]'
            }
        } else {
            if (shouldShowEmergencyBreakNotification) {
                return 'md:min-h-[21rem]'
            } else {
                return 'md:min-h-[18.4rem]'
            }
        }
    }
    function dispatchTypeChanged (value: DispatchTypeOrderBuddy | '') {
        if (value) {
            nextTick(() => {
                checkout().setDispatchType(value, false)
                selectedTabComponent.value = checkout().input.dispatchType === DispatchTypeOrderBuddy.DELIVER ? HeroDelivery : HeroPickup
            })
        }
    }
</script>
