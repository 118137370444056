<template>
    <div class="flex flex-col gap-4">
        <h2
            v-if="webshopSettings().isOneDispatchType"
            class="text-2xl"
        >
            {{
                $t(DispatchTypeOrderBuddy.DELIVER)
            }}
        </h2>
        <span>
            {{ webshopSettings().global?.shouldShowStreetNumberForDelivery ? $t('dispatchDeliveryWithHouseNumber') : $t('dispatchDelivery') }}
        </span>
        <FormKit
            v-slot="{ state: { loading } }"
            type="form"
            :actions="false"
            :incomplete-message="false"
            form-class="flex flex-col gap-4"
            @submit="navigateToDispatchType(DispatchTypeOrderBuddy.DELIVER)"
        >
            <div class="flex w-full flex-col gap-4 md:flex-row">
                <FormKit
                    ref="addressGroup"
                    v-model="heroDeliveryForm"
                    name="addressGroup"
                    type="group"
                    validation="+handleIsInRange"
                    :validation-rules="{ handleIsInRange }"
                    validation-visibility="dirty"
                >
                    <div class="flex w-full flex-row gap-4 md:w-6/12">
                        <FormKit
                            id="postalCode"
                            :disabled="loading || formIsLoading"
                            type="text"
                            name="postalCode"
                            :validation="[['required'], ['matches', /^\d{4}\s?[A-Za-z]{2}$/]]"
                            :placeholder="t('postalCode')"
                            :validation-messages="{
                                required: `${t('error.postalCodeRequired')}`,
                                matches: `${t('error.postalCodeInvalid')}`
                            }"
                            :classes="{
                                outer: `${webshopSettings().global?.shouldShowStreetNumberForDelivery ? 'w-8/12' : 'w-full'}`,
                                input: 'uppercase placeholder:capitalize'
                            }" />
                        <FormKit
                            v-if="webshopSettings().global?.shouldShowStreetNumberForDelivery"
                            id="streetNumber"
                            :disabled="loading || formIsLoading"
                            type="text"
                            name="streetNumber"
                            validation="required"
                            :validation-messages="{
                                required: `${t('error.streetNumberRequired')}`
                            }"
                            :placeholder="t('streetNumber')"
                            :classes="{
                                outer: 'w-4/12'
                            }" />
                    </div>
                </FormKit>
                <div class="md:grow">
                    <FormKit
                        type="submit"
                        name="submitButton"
                        :disabled="loading || formIsLoading"
                        :ignore="false">
                        <div class="flex h-6 items-center">
                            <Icon v-if="formIsLoading && !loading" :icon="IconLoading" class="flex animate-spin" />
                            <span v-else-if="!loading">
                                {{ $t('orderOnline') }}
                            </span>
                        </div>
                    </FormKit>
                </div>
            </div>
            <transition name="fade" mode="out-in">
                <FormKitMessages
                    :node="addressGroup?.node"
                    :library="FormKitMessagesCustomComponents"
                    :sections-schema="{
                        message: {
                            $el: undefined,
                            $cmp: 'NotificationContentModifyPostalCode',
                            props: {
                                type: 'error',
                                postCodeSwitchModal: false
                            }
                        }
                    }"
                />
            </transition>
        </FormKit>
        <Notification v-if="getEmergencyBreakActive(DispatchTypeOrderBuddy.DELIVER)" :type="getEmergencyBreakNotification(DispatchTypeOrderBuddy.DELIVER).type">
            {{ getEmergencyBreakNotification(DispatchTypeOrderBuddy.DELIVER).message }}
        </Notification>
        <div>
            <i18n-t keypath="dispatchSeeLocations" tag="span" scope="global">
                <template #menu>
                    <nuxt-link :to="localePath('/shop')" class="underline">
                        {{ $t('menu') }}
                    </nuxt-link>
                </template>
            </i18n-t>.
        </div>
    </div>
</template>

<script setup lang="ts">
    import type { FormKitNode } from '@formkit/core'
    import { FormKitMessages } from '@formkit/vue'
    import { webshopSettings } from '~/store/webshopSettings'
    import { requestLocation } from '~/composables/requestLocation'
    import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
    import Icon from '~/components/Icon.vue'
    import IconLoading from '@/assets/icons/loading.svg'
    import { checkout } from '~/store/checkout'
    import { getEmergencyBreakActive, getEmergencyBreakNotification } from '~/composables/emergencyBreak'
    import Notification from '~/components/Notification/Notification.vue'
    import { navigateToDispatchType } from '~/composables/navigateToDispatchType'
    import NotificationContentModifyPostalCode from '~/components/Notification/Content/NotificationContentModifyPostalCode.vue'
    const { t } = useI18n()
    const localePath = useLocalePath()
    const formIsLoading = ref(false)
    const heroDeliveryForm = ref({})
    const addressGroup = ref()
    const FormKitMessagesCustomComponents = { NotificationContentModifyPostalCode: markRaw(NotificationContentModifyPostalCode) }
    onMounted(async () => {
        if (checkout()?.input?.customer?.addressGroup?.postalCode === '' && checkout()?.input?.customer?.addressGroup?.streetNumber === '') {
            formIsLoading.value = true
            const userAddress = await requestLocation()
            formIsLoading.value = false
            if (userAddress) {
                checkout().setUserDeliveryAddress({
                    postalCode: userAddress.postcodes[0],
                    streetNumber: userAddress.streetnumber.toString()
                })
            }
        }
        heroDeliveryForm.value = checkout().input.customer.addressGroup
    })
    function handleIsInRange (node: FormKitNode) {
        try {
            const value = node.value as { postalCode?: string, streetNumber?: string }
            if (value?.postalCode !== '' && value?.streetNumber !== '') {
                return hasPostalCode() && isPostalCodeInRange()
            }
            return true
        } catch (e) {
            return false
        }
    }
</script>
