<template>
    <section v-if="webshopSettings()?.filteredContentBlocks?.length">
        <div
            v-for="(block, index) in webshopSettings().filteredContentBlocks"
            :key="block.title"
            class="py-10 sm:py-28"
            :class="index % 2 === 0 ? 'bg-gray-100' : ''"
        >
            <div class="container m-auto">
                <div class="flex flex-col items-center justify-center gap-6 md:flex-row md:gap-12 lg:gap-20" :class="{ 'md:flex-row-reverse': block.imagePosition === 'right' }">
                    <div v-if="block.image?.path" class="flex self-stretch md:w-1/2">
                        <NuxtImg
                            class="size-full rounded-xl object-cover"
                            :placeholder="img(block.image?.path, { h: 40, w: 40, fit: 'cover' })"
                            :src="block.image?.path"
                            loading="lazy"
                            width="580"
                            height="400"
                            alt="content image" />
                    </div>
                    <div class="flex w-full flex-col items-start gap-4 md:w-1/2">
                        <h5 v-if="block.title" class="text-4xl font-bold leading-none tracking-tight">
                            {{ block.title }}
                        </h5>
                        <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
                        <div v-if="block.text" class="rich-text text-lg font-normal" v-html="block.text" />
                        <template
                            v-if="block.shouldShowButton && block.buttonUrl"
                        >
                            <nuxt-link
                                v-if="(block.buttonUrl === '[delivery]' && webshopSettings().deliveryIsEnabled) || (block.buttonUrl === '[pickup]' && webshopSettings().pickupIsEnabled)"
                                :to="getDistpatchTypeUrl(block.buttonUrl === '[delivery]' ? DispatchTypeOrderBuddy.DELIVER : DispatchTypeOrderBuddy.PICK_UP)"
                                class="flex cursor-pointer items-center gap-2 font-bold text-primary transition-opacity hover:opacity-70"
                            >
                                <span>{{ block.buttonText ? block.buttonText : $t('dispatchSeeLocations') }}</span>
                                <Icon :icon="IconChevronRight" class="w-4" />
                            </nuxt-link>
                            <a
                                v-else-if="block.buttonUrl !== '[delivery]' && block.buttonUrl !== '[pickup]'"
                                :href="block.buttonUrl"
                                target="_blank"
                                class="flex cursor-pointer items-center gap-2 font-bold text-primary transition-opacity hover:opacity-70"
                            >
                                <span>{{ block.buttonText }}</span>
                                <Icon :icon="IconChevronRight" class="w-4" />
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import { type ContentBlockOrderBuddy, DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
    import { webshopSettings } from '~/store/webshopSettings'
    import { getDistpatchTypeUrl } from '~/composables/navigateToDispatchType'
    import Icon from '~/components/Icon.vue'
    import IconChevronRight from '@/assets/icons/chevron-right.svg'
    defineProps<ContentBlockOrderBuddy>()
    const img = useImage() // TODO: use srcset
</script>
