<template>
    <div>
        <Hero />
        <HomePageContact id="contact" />
        <HomePageContentBlocks id="about" />
        <HomePageReview v-if="reviews().hasReviews" id="review" :bg-fill="webshopSettings()?.filteredContentBlocks?.length % 2 === 0" />
        <HomePageBusinessInfo id="businessinfo" :bg-fill="(webshopSettings()?.filteredContentBlocks?.length + (reviews().hasReviews ? 1 : 0)) % 2 === 0" />
    </div>
</template>

<script setup async lang="ts">
    import { reviews } from '~/store/reviews'
    import HomePageContact from '~/components/HomePage/HomePageContact.vue'
    import HomePageContentBlocks from '~/components/HomePage/HomePageContentBlocks.vue'
    import HomePageReview from '~/components/HomePage/HomePageReview.vue'
    import HomePageBusinessInfo from '~/components/HomePage/HomePageBusinessInfo.vue'
    import Hero from '~/components/Hero/Hero.vue'
    import { webshopSettings } from '~/store/webshopSettings'
    import { shop } from '~/store/shop'
    import { checkout } from '~/store/checkout'
    useHead({
        title: 'Home'
    })
    await reviews().fetchReviewStats()
    await reviews().fetchCustomerReviews(3, 1)
    onMounted(async () => {
        await nextTick(async () => {
            // TODO: move to middleware and make more intelligent
            await shop().fetchAllCategories(checkout().input.dispatchType)
        })
    })
</script>
